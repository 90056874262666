.navigation-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 2rem;
  z-index: 1;
  width: 100%;
  top: 0;
  border-bottom: solid 1px var(--primary-color);
  opacity: 0.9;
}

.navigation-bar a {
  color: #fff;
  padding: 0.45rem;
  margin: 0 0.25rem;
}

.navigation-bar ul {
  display: flex;
}
