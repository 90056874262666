.photo-point-slider {
  height: 100px;
  justify-content: center;
  align-items: left;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}

.bib-block {
  /* position: relative;
  bottom: 40px;
  left: 100px;
  background-color: rgb(255, 255, 255);
  color: rgb(11, 11, 11); */
  /* padding-left: 5px;
  padding-right: 5px; */
  /* display: flex; */
  /* justify-content: center;
  align-items: center; */

  /* position: relative;
  bottom: 40px;
  left: 100px; */
  background-color: rgb(255, 255, 255);
  color: rgb(11, 11, 11);
  /* padding-left: 5px;
  padding-right: 5px; */
  /* display: flex; */
  justify-content: center;
  align-items: center;
}

.rti--container {
  position: relative;
  bottom: 40px;
  height: 100px;
  max-width: 520px;
}
