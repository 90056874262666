.progress {
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #e5d9d9;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
}

.progress-bar {
  float: left;
  width: 0%;
  height: 100%;
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;
  color: #111010;
  text-align: center;
  background-color: #337ab7;
  -webkit-box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

.progress-bar-info {
  background-color: #5bc0de;
}
