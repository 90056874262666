#search-location-container {
  /* border: 2px dashed #444; */
  height: 125px;
  text-align: justify;
  -ms-text-justify: distribute-all-lines;
  text-justify: distribute-all-lines;
  /* just for demo */
  min-width: 612px;
}

.box1,
.box2,
.box3,
.box4,
.box5 {
  width: 100px;
  height: 125px;
  vertical-align: top;
  display: inline-block;
  display: inline;
  zoom: 1;
}
