.slider {
  position: relative;
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landscape-image {
  width: 1000px;
  height: 600px;
  width: 100%;
  border-radius: 10px;
}

.portrait-image {
  width: 600px;
  height: 600px;
  width: 100%;
  border-radius: 10px;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 32px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 32px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}

.bib-block {
  /* position: relative;
  bottom: 40px;
  left: 100px;
  background-color: rgb(255, 255, 255);
  color: rgb(11, 11, 11); */
  /* padding-left: 5px;
  padding-right: 5px; */
  /* display: flex; */
  /* justify-content: center;
  align-items: center; */

  /* position: relative;
  bottom: 40px;
  left: 100px; */
  background-color: rgb(255, 255, 255);
  color: rgb(11, 11, 11);
  /* padding-left: 5px;
  padding-right: 5px; */
  /* display: flex; */
  justify-content: center;
  align-items: center;
}

.bib-text {
  /* position: relative;
  bottom: 40px;
  left: 100px;
  background-color: rgb(255, 255, 255);
  color: rgb(11, 11, 11); */
  /* padding-left: 5px;
  padding-right: 5px; */
  /* display: flex; */
  /* justify-content: center;
  align-items: center; */

  /* position: relative;
  bottom: 40px;
  left: 100px; */

  /* padding-left: 5px;
  padding-right: 5px; */
  /* display: flex; */
  position: relative;
  bottom: 30px;
  left: 400px;
}

.invalid-images {
  position: relative;
  bottom: 115px;
  left: 950px;
  /* background-color: rgb(255, 255, 255);
  color: rgb(11, 11, 11); */
  /* padding-left: 5px;
  padding-right: 5px; */
  /* display: flex;
  justify-content: center;
  align-items: center; */

  /* border-radius: 7px;
  height: 30px;
  width: 30px; */
}

.rti--container {
  position: relative;
  bottom: 40px;
  left: 400px;
  height: 85px;
  max-width: 520px;
}
