.branding-container {
  margin: 0;
}

.portrait {
  float: left;
  width: 50%;
  overflow: hidden;
}

.landscape {
  float: left;
  width: 50%;
  overflow: hidden;
}
