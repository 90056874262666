.payment-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.payment-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  margin-bottom: 20px;
}

.payment-content-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}

.payment-app {
  font-family: sans-serif;
  text-align: center;
}
