.pagination {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
  
  .pagination button {
    background-color: #f0f0f0; /* Light gray background */
    color: #333; /* Dark text for better contrast */
    border: 1px solid #ddd; /* Border for separation */
    margin: 0 5px; /* Spacing between buttons */
    padding: 5px 10px; /* Button padding */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .pagination button:hover {
    background-color: #ddd; /* Slightly darker on hover */
  }
  
  .pagination button.active {
    background-color: #fffefe; /* Bootstrap-like blue */
    color: white; /* White text for contrast */
    border-color: #a6b2bf; /* Blue border */
  }
  